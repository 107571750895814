import { jsxs as B, jsx as n } from "react/jsx-runtime";
import { styled as e, css as s } from "styled-components";
import { forwardRef as l } from "react";
const i = {
  xLarge: `
    padding: 16px 40px;
    font-size: 20px;
    line-height: 32px;
  `,
  large: `
    padding: 16px 30px;
    font-size: 18px;
    line-height: 24px;
  `,
  medium: `
    padding: 12px 22px;
    font-size: 16px;
    line-height: 24px;
  `,
  small: `
    padding: 8px 20px;
    font-size: 14px;
    line-height: 24px;
  `,
  xSmall: `
    padding: 8px 16px;
    font-size: 12px;
    line-height: 16px;
  `
}, k = e.button`
  --button-text-color: ${({ theme: o }) => o.colors.black100};
  --button-hover-text-color: ${({ theme: o }) => o.colors.black100};
  --button-disabled-text-color: ${({ theme: o }) => o.colors.black100};
  --button-bg-color: ${({ theme: o }) => o.colors.blue60};
  --button-hover-bg-color: ${({ theme: o }) => o.colors.blue50};
  --button-disabled-bg-color: ${({ theme: o }) => o.colors.black80};

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: fit-content;
  border-radius: 8px;
  border: none;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  user-select: none;
  height: fit-content;
  font-family: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  color: var(--button-text-color);
  background: var(--button-bg-color);

  [data-name='hn-icon'] {
    fill: var(--button-text-color);
  }

  ${({ size: o }) => i[o] ?? i.small};

  &:focus-visible {
    outline: auto;
    background: var(--button-hover-bg-color);
    color: var(--button-hover-text-color);

    [data-name='hn-icon'] {
      fill: var(--button-hover-text-color);
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: var(--button-hover-bg-color);
      color: var(--button-hover-text-color);

      [data-name='hn-icon'] {
        fill: var(--button-hover-text-color);
      }
    }
  }

  &:disabled {
    background: var(--button-disabled-bg-color);
    color: var(--button-disabled-text-color);
    cursor: not-allowed;

    [data-name='hn-icon'] {
      fill: var(--button-disabled-text-color);
    }
  }

  ${({ $fit: o }) => o && s`
      width: fit-content;
      height: fit-content;
    `};
`, w = e.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & > svg {
    margin-left: ${({ $margin: o, $position: t }) => t === "left" ? 0 : `${o}px`};
    margin-right: ${({ $margin: o, $position: t }) => t === "right" ? 0 : `${o}px`};
  }
`, u = ({ position: o, Icon: t, size: r }) => {
  const a = C[r], b = ["small", "xSmall"].includes(r) ? 4 : 8;
  return t ? /* @__PURE__ */ n(w, { $position: o, $margin: b, children: /* @__PURE__ */ n(t, { size: a }) }) : null;
}, c = l(
  ({
    size: o = "medium",
    disabled: t = !1,
    onClick: r,
    children: a,
    LeftIcon: b,
    RightIcon: d,
    tabIndex: p,
    fit: g = !1,
    name: x,
    value: h,
    className: $,
    type: v = "button",
    ...f
  }, m) => /* @__PURE__ */ B(
    k,
    {
      size: o,
      disabled: t,
      onClick: r,
      tabIndex: p,
      $fit: g,
      name: x,
      value: h,
      className: $,
      type: v,
      ref: m,
      ...f,
      children: [
        /* @__PURE__ */ n(u, { Icon: b, size: o, position: "left" }),
        a,
        /* @__PURE__ */ n(u, { Icon: d, size: o, position: "right" })
      ]
    }
  )
), C = {
  xLarge: 32,
  large: 32,
  medium: 24,
  small: 16,
  xSmall: 16
}, y = e(c)`
  --button-text-color: ${({ theme: o }) => o.colors.black100};
  --button-hover-text-color: ${({ theme: o }) => o.colors.black100};
  --button-bg-color: ${({ theme: o }) => o.colors.blue60};
  --button-hover-bg-color: ${({ theme: o }) => o.colors.blue50};
`, q = l(({ as: o, ...t }, r) => /* @__PURE__ */ n(y, { ref: r, forwardedAs: o, ...t })), I = e(c)`
  --button-bg-color: ${({ theme: o }) => o.colors.darkBlue40};
  --button-hover-bg-color: ${({ theme: o }) => o.colors.darkBlue30};
`, E = l((o, t) => /* @__PURE__ */ n(I, { ref: t, ...o })), z = e(c)`
  --button-bg-color: ${({ theme: o }) => o.colors.purple40};
  --button-hover-bg-color: ${({ theme: o }) => o.colors.purple30};
`, F = l((o, t) => /* @__PURE__ */ n(z, { ref: t, ...o })), L = e(c)`
  --button-text-color: ${({ theme: o }) => o.colors.blue50};
  --button-hover-text-color: ${({ theme: o }) => o.colors.blue30};
  --button-disabled-text-color: ${({ theme: o }) => o.colors.black80};
  --button-bg-color: transparent;
  --button-hover-bg-color: transparent;
  --button-disabled-bg-color: transparent;
`, S = l((o, t) => /* @__PURE__ */ n(L, { ref: t, ...o })), j = e(S)`
  padding: 0;
  border-radius: 0;
`, D = l((o, t) => /* @__PURE__ */ n(j, { ref: t, ...o })), G = e(c)`
  --button-text-color: ${({ theme: o }) => o.colors.blue60};
  --button-hover-text-color: ${({ theme: o }) => o.colors.blue50};
  --button-disabled-text-color: ${({ theme: o }) => o.colors.black80};
  --button-bg-color: transparent;
  --button-hover-bg-color: transparent;
  --button-disabled-bg-color: transparent;

  padding: 0;
`, H = l(({ children: o, ...t }, r) => /* @__PURE__ */ n(G, { ref: r, "aria-label": "icon-button", ...t, fit: !0, children: o })), P = e(c)`
  --button-text-color: ${({ theme: o }) => o.colors.darkBlue40};
  --button-hover-text-color: ${({ theme: o }) => o.colors.darkBlue30};
  --button-disabled-text-color: ${({ theme: o }) => o.colors.black80};
  --button-bg-color: transparent;
  --button-hover-bg-color: transparent;
  --button-disabled-bg-color: transparent;

  padding: 0;
`, J = l(({ children: o, ...t }, r) => /* @__PURE__ */ n(P, { ref: r, ...t, fit: !0, children: o })), W = e(c)`
  --button-text-color: ${({ theme: o }) => o.colors.purple40};
  --button-hover-text-color: ${({ theme: o }) => o.colors.purple30};
  --button-disabled-text-color: ${({ theme: o }) => o.colors.black80};
  --button-bg-color: transparent;
  --button-hover-bg-color: transparent;
  --button-disabled-bg-color: transparent;

  padding: 0;
`, K = l(({ children: o, ...t }, r) => /* @__PURE__ */ n(W, { ref: r, ...t, fit: !0, children: o })), A = e(c)`
  --button-text-color: ${({ theme: o }) => o.colors.blue50};
  --button-hover-text-color: ${({ theme: o }) => o.colors.blue30};
  --button-bg-color: ${({ theme: o }) => o.colors.blue95};
  --button-hover-bg-color: ${({ theme: o }) => o.colors.blue90};
`, N = l((o, t) => /* @__PURE__ */ n(A, { ref: t, ...o })), M = e(c)`
  --button-text-color: ${({ theme: o }) => o.colors.blue95};
  --button-hover-text-color: ${({ theme: o }) => o.colors.blue90};
  --button-disabled-text-color: ${({ theme: o }) => o.colors.black80};
  --button-bg-color: transparent;
  --button-hover-bg-color: transparent;
  --button-disabled-bg-color: transparent;

  padding: 0;
`, Q = l(({ children: o, ...t }, r) => /* @__PURE__ */ n(M, { ref: r, ...t, fit: !0, children: o })), U = e(D)`
  font-weight: 400;
  font-size: 13px;
  line-height: 1;

  ${({ theme: o, $danger: t }) => t && s`
      --button-text-color: ${o.colors.red60};
      --button-hover-text-color: ${o.colors.red40};
    `};
`;
export {
  q as BlueButton,
  H as BlueIconButton,
  c as Button,
  E as DarkBlueButton,
  J as DarkBlueIconButton,
  C as IconSize,
  N as LightBlueButton,
  Q as LightBlueIconButton,
  D as LinkButton,
  U as OptionButton,
  F as PurpleButton,
  K as PurpleIconButton,
  S as TextButton
};
